import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import "./Navbar.css";
import NavbarCollpapse from "./NavbarCollpapse";
import { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/logo.png";

function Navigationbar() {
  const [expanded, setExpanded] = useState(false);

  const handleNavItemClick = () => {
    setExpanded(false);
  };
  return (
    <Navbar expand="lg" expanded={expanded} className={`navbar bg-white`}>
      <Container fluid>
        <Navbar.Brand>
          <Link to="/" style={{ color: "black", textDecoration: "none" }}>
            <img
              className="img-fluid"
              style={{ maxWidth: "20vh" }}
              src={logo}
              alt="TechBay"
            />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle
          onClick={() => setExpanded(!expanded)}
          aria-controls="basic-navbar-nav"
        />
        <NavbarCollpapse onSelect={handleNavItemClick} />
      </Container>
    </Navbar>
  );
}

export default Navigationbar;
