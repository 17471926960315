// CartContext.js
import React, { createContext, useContext, useReducer, useEffect } from "react";
import Cookies from "js-cookie";

// Initial state for the cart
const initialState = {
  items: [],
  isOpen: false,
};

// Define actions for the reducer
const cartReducer = (state, action) => {
  switch (action.type) {
    case "ADD_TO_CART":
      const existingItemIndex = state.items.findIndex(
        (item) =>
          item.id === action.payload.id && item.device === action.payload.device
      );

      if (existingItemIndex !== -1) {
        const updatedItems = [...state.items];
        updatedItems[existingItemIndex].quantity += action.payload.quantity;
        return {
          ...state,
          items: updatedItems,
        };
      } else {
        return {
          ...state,
          items: [...state.items, action.payload],
        };
      }

    case "CHANGE_QUANTITY":
      const index = state.items.findIndex(
        (item) =>
          item.id === action.payload.id && item.device === action.payload.device
      );
      if (index !== -1) {
        const updatedItems = [...state.items];
        updatedItems[index].quantity = action.payload.quantity;
        return {
          ...state,
          items: updatedItems,
        };
      }

    case "REMOVE_FROM_CART":
      if (action.payload === "all") {
        return {
          ...state,
          items: [],
        };
      } else {
        return {
          ...state,
          items: state.items.filter(
            (item) =>
              item.id !== action.payload.id ||
              item.device !== action.payload.device
          ),
        };
      }
    case "OPEN_CART":
      console.log("open");
      return {
        ...state,
        isOpen: true,
      };

    case "CLOSE_CART":
      return {
        ...state,
        isOpen: false,
      };

    default:
      return state;
  }
};

// Create the context
export const CartContext = createContext();

// Create a provider component
const CartProvider = ({ children }) => {
  const storedCart = Cookies.get("cart");
  const initialCartState = storedCart
    ? { items: JSON.parse(storedCart), isOpen: false }
    : { ...initialState, isOpen: false };
  const [state, dispatch] = useReducer(cartReducer, initialCartState);
  // Actions to interact with the cart
  const addToCart = (item) => {
    dispatch({ type: "ADD_TO_CART", payload: item });
  };

  const removeFromCart = (itemId) => {
    dispatch({ type: "REMOVE_FROM_CART", payload: itemId });
  };

  const openCart = () => {
    dispatch({ type: "OPEN_CART" });
  };

  // Add a new function to close the cart
  const closeCart = () => {
    dispatch({ type: "CLOSE_CART" });
  };

  const changeQuantity = (itemId, device, newQuantity) => {
    dispatch({
      type: "CHANGE_QUANTITY",
      payload: { id: itemId, device, quantity: newQuantity },
    });
  };

  useEffect(() => {
    Cookies.set("cart", JSON.stringify(state.items), {
      expires: 7,
      path: "/",
    });
  }, [state.items]);

  return (
    <CartContext.Provider
      value={{
        cart: state,
        addToCart,
        removeFromCart,
        openCart,
        closeCart,
        changeQuantity,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

// Custom hook to access the cart context
const useCart = () => {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error("useCart must be used within a CartProvider");
  }
  return context;
};

export { CartProvider, useCart };
