import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import "./QuantityInput.css";
import { useCart } from "../Cart/CartContext";

const QuantityInput = (props) => {
  const [quantity, setQuantity] = useState(props.data || 1);
  const { changeQuantity } = useCart();

  const handleIncrement = () => {
    if (quantity < 5) {
      setQuantity(quantity + 1);
      props.onDataChange(quantity);
      if (props.source) {
        changeQuantity(props.item.id, props.item.device, quantity + 1);
      }
    }
  };

  const handleDecrement = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
      props.onDataChange(quantity - 2);
      if (props.source) {
        changeQuantity(props.item.id, props.item.device, quantity - 1);
      }
    }
  };

  return (
    <Form>
      <Form.Group controlId="quantityInput">
        <div className="input-group" style={{ width: `${props.width}` }}>
          <div className="input-group-prepend">
            <Button variant="outline-secondary" onClick={handleDecrement}>
              -
            </Button>
          </div>
          <Form.Control
            className="text-center"
            type="number"
            value={quantity}
            readOnly
          />
          <div className="input-group-append">
            <Button variant="outline-secondary" onClick={handleIncrement}>
              +
            </Button>
          </div>
        </div>
      </Form.Group>
    </Form>
  );
};

export default QuantityInput;
