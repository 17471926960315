import React from "react";
import Nav from "react-bootstrap/Nav";
import { Link, useLocation } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import SearchBox from "./SearchBox";
import { useCart } from "../Cart/CartContext";
import { useAuth } from "../Context/AuthContext";
import Cart from "../Cart/Cart";

const NavbarCollpapse = ({ onSelect }) => {
  const { cart, openCart } = useCart();
  const location = useLocation();
  const { isLoggedIn, logout } = useAuth();

  const handleSignOut = () => {
    logout();
  };

  const totalItems = cart.items.length;
  const isHomeActive = location.pathname === "/";

  const cartOpenHandler = () => {
    openCart();
  };

  return (
    <>
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="z-index-1 bg-white p-4 mx-auto text-dark text-center d-flex align-items-center mt-1">
          <Nav.Link
            as={Link}
            to="/"
            className={isHomeActive ? "navLink active" : "navLink"}
            style={{ marginLeft: "2rem", padding: "10px" }}
            onClick={onSelect}
          >
            Home
          </Nav.Link>
          <Nav.Link
            as={Link}
            to="/skins"
            style={{ marginLeft: "2rem", padding: "10px" }}
            className={`${
              location.pathname === "/skins" ? "navLink active" : "navLink"
            } ml-2`}
            onClick={onSelect}
          >
            Skins
          </Nav.Link>
          <a
            style={{
              marginLeft: "2rem",
              padding: "10px",
              cursor: "pointer",
              textDecoration: "none",
            }}
            className={"navLink ml-2"}
            onClick={() => {
              cartOpenHandler();
              onSelect();
            }}
          >
            {`Cart(${totalItems})`}
          </a>

          <Nav.Link
            as={Link}
            to={!isLoggedIn ? "/logIn" : ""}
            style={{ marginLeft: "2rem", padding: "10px" }}
            className={`${
              location.pathname === "/myAccount" ? "navLink active" : "navLink"
            } ml-2`}
            onClick={() => {
              handleSignOut();
              onSelect();
            }}
          >
            {isLoggedIn ? "SignOut" : "Log In"}
          </Nav.Link>
        </Nav>
        <SearchBox />
      </Navbar.Collapse>
      {cart.isOpen && <Cart />}
    </>
  );
};

export default NavbarCollpapse;
