import React, { useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useCart } from "./CartContext";
import { Link } from "react-router-dom";
import { useAuth } from "../Context/AuthContext";
import Backdrop from "../Helpers/Backdrop";
import "./Cart.css";
import { IoMdClose } from "react-icons/io";
import { RiDeleteBin6Line } from "react-icons/ri";
import QuantityInput from "../ProductDetail/QuantityInput";

const Cart = () => {
  const { cart, closeCart, removeFromCart, changeQuantity } = useCart();
  const { isLoggedIn } = useAuth();
  const [quantity, setQuantity] = useState(1);

  const quantityChangeHandler = (data) => {
    setQuantity(data);
  };

  const total = () => {
    return cart.items
      .reduce((total, item) => total + item.price * item.quantity, 0)
      .toFixed(2);
  };

  function closeButtonHandler() {
    closeCart();
  }

  return (
    <Container className="mt-5 mb-5">
      {cart.isOpen && <Backdrop onClick={closeCart} />}
      <div className={`cart-container ${cart.isOpen ? "open" : ""}`}>
        <div className="d-flex m-4 justify-content-between">
          <div className="d-flex align-items-center">
            <h2 className="text-center">Cart</h2>
          </div>
          <div className="d-flex align-items-center">
            <IoMdClose
              style={{ fontSize: "2.5vh" }}
              className="cut-icon"
              onClick={closeButtonHandler}
            />
          </div>
        </div>
        <hr />
        <Container className="d-flex flex-column align-items-md-center justify-content-between">
          {cart.items.map((item) => (
            <>
              <Row
                className="product-detail"
                style={{ marginBottom: "3vh" }}
                key={item.id}
              >
                <Col xs={3}>
                  <img
                    className="img-fluid"
                    alt="image"
                    src={item.imageUrl}
                  ></img>
                </Col>
                <Col xs={7}>
                  <div>
                    <h5>{item.name}</h5>
                    <div className="d-flex mt-1">
                      <p style={{ color: "grey" }}>Brand</p>
                      <p>{`-${item.device.split(" ")[0]}`}</p>
                    </div>
                    <div className="d-flex flex-row">
                      <p style={{ color: "grey" }}>Model</p>
                      <p>{`-${item.device}`}</p>
                    </div>
                    <div
                      className="d-flex justify-content-between align-items-center"
                      style={{ width: "30vh" }}
                    >
                      <QuantityInput
                        width={"7rem"}
                        onDataChange={quantityChangeHandler}
                        data={item.quantity}
                        item={{
                          id: item.id,
                          device: item.device,
                          product: item.name,
                        }}
                        source={"cart"}
                      />
                      <div className="d-flex flex-column">
                        <div className="d-flex justify-content-between flex-row">
                          <p
                            style={{
                              marginRight: "1vh",
                              textDecoration: "line-through",
                            }}
                          >{`Rs. ${(+item.price + 200) * item.quantity}.00`}</p>
                          <p>{`Rs. ${item.price * item.quantity}.00`}</p>
                        </div>
                        <div>
                          <p
                            style={{ color: "green", fontWeight: "bold" }}
                          >{`(Save Rs ${
                            (+item.price + 200 - +item.price) * item.quantity
                          }.00)`}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xs={2} className="d-flex justify-content-center">
                  <RiDeleteBin6Line
                    style={{ fontSize: "2.5vh" }}
                    className="cut-icon"
                    onClick={() => {
                      const items = {
                        id: item.id,
                        device: item.device,
                      };
                      removeFromCart(items);
                    }}
                  />
                </Col>
              </Row>
            </>
          ))}
        </Container>
        <hr />
        <div style={{ float: "right" }}>
          <strong>{`Total : Rs ${total()}`}</strong>
        </div>
        {/* ... (unchanged code for rendering cart items) */}
        <Link
          to={isLoggedIn ? "/address" : "/login"}
          className="d-flex justify-content-center"
          style={{ width: "44vh" }}
        >
          <Button
            style={{ width: "80%" }}
            className="btn btn-dark mt-3"
            onClick={closeCart}
          >
            Check Out
          </Button>
        </Link>
      </div>
    </Container>
  );
};

export default Cart;
