// AuthContext.js
import { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { backendApi } from "../../App";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  const login = () => {
    setIsLoggedIn(true);
  };

  const logout = () => {
    setIsLoggedIn(false);
    localStorage.removeItem("token");
    navigate("/", { replace: true });
  };

  // useEffect(() => {
  //   const token = localStorage.getItem("JWTTOKEN");
  //   const checkIsLoggedIn = async () => {
  //     try {
  //       const response = await axios.get(`${backendApi}/user/checkLogIn`, {
  //         headers: { Authorization: token },
  //       });
  //       if (response.data.success) {
  //         return login();
  //       } else {
  //         return;
  //       }
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   };
  //   checkIsLoggedIn();
  // }, []);

  return (
    <AuthContext.Provider value={{ isLoggedIn, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
