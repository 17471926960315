import React from "react";

const Header = () => {
  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ backgroundColor: "black", height: "2.5rem" }}
    >
      <p style={{ color: "white", margin: "0", padding: "0" }}>
        FREE SHIPPING OVER ₹398
      </p>
    </div>
  );
};

export default Header;
