import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FaFacebook, FaTwitter, FaYoutube } from "react-icons/fa";
import { PiInstagramLogoFill } from "react-icons/pi";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Footer.css";
import { Link } from "react-router-dom";
import { useAuth } from "../Context/AuthContext";

const Footer = () => {
  const { isLoggedIn, logout } = useAuth();
  return (
    <Container fluid className="footer p-4 bg-light">
      <Row>
        <Col lg={3}>
          <h5>Vision</h5>
          <p>
            <b style={{ fontWeight: "300", textDecoration: "none" }}>
              Creativity, Expression, & Exploration
            </b>
          </p>
        </Col>
        <Col lg={3}>
          <ul style={{ padding: "0" }}>
            <li>
              <h5>Pages</h5>
            </li>
            <li>
              <Link className="nav-link" to="/about">
                <b>About Us</b>
              </Link>
            </li>
            <li>
              <Link className="nav-link" to="/contact">
                <b>Contact Us</b>
              </Link>
            </li>
            <li>
              <Link className="nav-link" to="/terms">
                <b>Terms and condition</b>
              </Link>
            </li>
            <li>
              <Link className="nav-link" to="/policies">
                <b>Refunds/Cancellations/Shipping Policy</b>
              </Link>
            </li>
            <li>
              <Link className="nav-link" to="/faq">
                <b>FAQ</b>
              </Link>
            </li>
          </ul>
        </Col>
        <Col lg={3} className="account-col">
          <ul style={{ padding: 0 }}>
            <li>
              <h5>My Accounts</h5>
            </li>
            {isLoggedIn ? (
              <li>
                <a onClick={logout}>
                  <b id="footer_login">SignOut</b>
                </a>
              </li>
            ) : (
              <li>
                <Link className="nav-link" to="/login">
                  <b id="footer_login">Login</b>
                </Link>
              </li>
            )}
            {isLoggedIn ? (
              <li>
                <a onClick={logout}>
                  <b id="footer_login">Account</b>
                </a>
              </li>
            ) : (
              <li>
                <Link className="nav-link" to="/login">
                  <b id="footer_login">Register</b>
                </Link>
              </li>
            )}
            {/* <li>
              <Link className="nav-link" to="/track-order">
                <b>Track Order</b>
              </Link>
            </li> */}
          </ul>
        </Col>
        <Col id="social" lg={3} className="d-flex flex-column">
          <h5>Follow Us</h5>
          <a className="nav-link" href="#">
            <FaTwitter className="icon" />
            <span>
              <b>Twitter</b>
            </span>
          </a>

          <a className="nav-link" href="#">
            <FaFacebook className="icon" />

            <span>
              <b>Facebook</b>
            </span>
          </a>
          <a className="nav-link" href="#">
            <FaYoutube className="icon" />
            <span>
              <b>Youtube</b>
            </span>
          </a>

          <a className="nav-link" href="#">
            <PiInstagramLogoFill className="icon" />
            <span>
              <b>Instagram</b>
            </span>
          </a>
        </Col>
      </Row>
      <hr />
      <h5 className="text-center">Copyright © 2024 TechBay</h5>
    </Container>
  );
};

export default Footer;
