import React from "react";
import { MdOutlineManageSearch } from "react-icons/md";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Row, Col } from "react-bootstrap";
import axios from "axios";
import { backendApi } from "../../App";

const SearchBox = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();

  const handleSearch = async () => {
    console.log("Search term:", searchTerm);

    const response = await axios.get(`${backendApi}/products/getAllProducts`);

    const results = response.data.filter((item) =>
      item.title.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setSearchTerm("");

    navigate("/results", { state: { results, searchTerm } });
  };

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <Form className="d-flex ml-auto justify-content-center">
      <Row>
        <Col xs="auto">
          <Form.Control
            type="text"
            placeholder="Search"
            className=" mr-sm-2"
            value={searchTerm}
            onChange={handleInputChange}
          />
        </Col>
        <Col>
          <a
            style={{ cursor: "pointer" }}
            onClick={handleSearch}
            onSubmit={handleSearch}
          >
            <MdOutlineManageSearch style={{ fontSize: "2.4rem" }} />
          </a>
        </Col>
      </Row>
    </Form>
  );
};

export default SearchBox;
