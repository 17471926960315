import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { lazy, Suspense } from "react";
import Navigationbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import { CartProvider } from "./components/Cart/CartContext";
import { AuthProvider } from "./components/Context/AuthContext";
import Header from "./components/Header/Header.js";

const Home = lazy(() => import("./components/Home/Home"));
const Skins = lazy(() => import("./components/Skins/Skins"));
const About = lazy(() => import("./components/About/About"));
const ProductDetail = lazy(() => import("./components/ProductDetail/ProductDetail"));
const Cart = lazy(() => import("./components/Cart/Cart"));
const Contact = lazy(() => import("./components/Contact/Contact"));
const SearchResult = lazy(() => import("./components/SearchResults/SeachResult"));
const TermsAndConditions = lazy(() => import("./components/Policies/TermsAndConditions"));
const Login = lazy(() => import("./components/Login/Login"));
const SignUp = lazy(() => import("./components/SignUp/SignUp"));
const ForgotPassoword = lazy(() => import("./components/ForgotPassword/ForgotPassoword"));
const Address = lazy(() => import("./components/Address/Address"));
const OrderStatus = lazy(() => import("./components/OrderStatus/OrderStatus"));
const CategorySkins = lazy(() => import('./components/Skins/CategorySkins.js'))
// const Admin = lazy(() => import("./components/Admin/Admin.js"));

export const backendApi = "https://techbay-backend-api.vercel.app";
// export const backendApi = "http://localhost:5000"


export function App() {
  return (
    <div>
      <Router>
        <AuthProvider>
          <CartProvider>
            <Header />
            <Navigationbar />
            <Suspense fallback={<div>Loading...</div>}>
              <Routes>
                <Route path="/" element={<Home />} exact />
                <Route path="/skins" element={<Skins />} exact />
                <Route path="/about" element={<About />} exact />
                <Route path="/product/details/:id" element={<ProductDetail />} exact />
                <Route path="/products/getCategoryProducts/:id" element={<CategorySkins />} exact />
                <Route path="/cart" element={<Cart />} exact />
                <Route path="/contact" element={<Contact />} exact />
                <Route path="/results" element={<SearchResult />} exact />
                <Route path="/terms" element={<TermsAndConditions />} exact />
                <Route path="/login" element={<Login />} exact />
                <Route path="/signUp" element={<SignUp />} exact />
                <Route path="/address" element={<Address />} exact />
                <Route path="/forgotPassword" element={<ForgotPassoword />} exact />
                <Route path="/order-status" element={<OrderStatus />} exact />
                {/* <Route path="/admin" element={<Admin />} exact /> */}
              </Routes>
            </Suspense>
            <br></br>
            <Footer />
          </CartProvider>
        </AuthProvider>
      </Router>
    </div>
  );
}
