import React from "react";
import PropTypes from "prop-types";

const Backdrop = ({ onClick }) => {
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent black background
        zIndex: 1000, // Adjust the z-index as needed
      }}
      onClick={onClick}
    />
  );
};

Backdrop.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default Backdrop;
